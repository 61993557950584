html[data-theme="light"] {

    --workspace-background: #f9f9f9;
    --workspace-widget-background: white;

    --disabled-layer-color: rgba(167, 167, 167, 0.2);

    --bg-0: #ffffff; /* ---- */
    --bg-100: #ffffff;
    --bg-200: #ffffff;
    --bg-400: #f7f5f5;
    --bg-500: #ffffff;
    --bg-800: #ffffff;
    --bg-popup: rgba(250, 250, 250, 0.87);
    --bg-popup-strong: rgb(225, 225, 225);
    --bg-popup-overlay: rgba(0, 0, 0, 0.37);
    --bg-dashboard-cell: rgba(0, 0, 0, 0.05);
    --bg-share: #f7f5f5;
    --bg-share-hover: rgba(0, 0, 0, 0.04);

    --element-neutral: rgba(65, 65, 65, 0.08);

    --input-background: rgba(255, 255, 255, 0.15);
    --input-background-hover: rgba(255, 255, 255, 0.2);
    --input-background-focus: rgba(255, 255, 255, 0.3);
    --input-background-active: #4c4c4c;

    --bg-element-neutral: rgba(0, 0, 0, 0.08);
    --bg-element-hover: rgba(0, 0, 0, 0.05);
    --bg-element-hover-hover: rgba(65, 65, 65, 0.05);
    
    --input-border: rgba(0, 0, 0, 0.15);
    --input-border-hover: rgba(0, 0, 0, 0.24);
    --input-border-focus: rgba(0, 0, 0, 0.34);


    --navigation-points-bg: rgba(0, 0, 0, 0.2);

    

    --border-lines: rgba(0, 0, 0, 0.15);

    --text-primary: rgba(0, 0, 0, 0.87);
    --text-secondary: rgba(0, 0, 0, 0.6);
    --text-third: rgba(0, 0, 0, 0.37);

    --color-white: #ffffff;
    --color-blue: #4093f4;
    --color-orange: rgba(245, 147, 0, 1);
    --color-red: rgba(239, 83, 80, 1);
    --color-orange: rgba(237, 104, 46, 1);
    --color-magenta: rgba(186, 104, 200, 1);
    --color-green: rgba(115, 178, 48, 1);

    --aspects-table-border: rgba(65, 65, 65, 0.08);
    --aspects-table-border-light: rgba(0, 0, 0, 0.2);
    --aspects-table-background-hover: rgba(65, 65, 65, 0.08);
    --aspects-table-background-current: #ffdbdb;

    --astro-table-border: #bdbdbd;
    --astro-table-border-light: #e0e0e0;
    --astro-table-object-major: #73b330;

    --maps-tabs-bg: rgba(0, 0, 0, 0.1);
    --maps-tabs-bg-current: rgba(0, 0, 0, 0.3);

    /* MAP MAP MAP */

    
    --icon-primary: rgba(0, 0, 0, 0.87);

    --circle-internal-background: #f6f9fb;
    --circle-border: #b0bec5;

    --circle-zodiacs-border: #c9d6dc;
    --circle-zodiacs-background: #c9d6dc;
    --circle-zodiacs-elements-common: rgba(33, 33, 33, 0.6);
    --circle-zodiacs-elements-land: #66bb6a;
    --circle-zodiacs-elements-air: #9575cd;
    --circle-zodiacs-elements-water: #537dcf;
    --circle-zodiacs-elements-fire: #ff8a65;
    --circle-zodiacs-icons-land: var(--circle-zodiacs-elements-common);
    --circle-zodiacs-icons-air: var(--circle-zodiacs-elements-common);
    --circle-zodiacs-icons-water: var(--circle-zodiacs-elements-common);
    --circle-zodiacs-icons-fire: var(--circle-zodiacs-elements-common);
    --circle-zodiacs-sectors-display-light: block;

    --circle-zodiacs-elements-common-rgb: 33, 33, 33, 0.6;
    --circle-zodiacs-elements-land-rgb: 102, 187, 106;
    --circle-zodiacs-elements-air-rgb: 149, 117, 205;
    --circle-zodiacs-elements-water-rgb: 83, 125, 207;
    --circle-zodiacs-elements-fire-rgb: 255, 138, 101;
    --circle-zodiacs-icons-land-rgb: var(--circle-zodiacs-elements-land-rgb);
    --circle-zodiacs-icons-air-rgb: var(--circle-zodiacs-elements-air-rgb);
    --circle-zodiacs-icons-water-rgb: var(--circle-zodiacs-elements-air-rgb);
    --circle-zodiacs-icons-fire-rgb: var(--circle-zodiacs-elements-fire-rgb);

    --circle-houses-usual: rgba(0, 0, 0, 0.37);
    --circle-houses-special: rgba(0, 0, 0, 0.87);
    --circle-houses-alternative: #7986cb;

    --circle-houses-usual-rgb: 161, 161, 161;
    --circle-houses-special-rgb: 33, 33, 33;
    --circle-houses-alternative-rgb: 121, 134, 203;

    --circle-aspects-90: #191919;
    --circle-aspects-180: #191919;

    --circle-aspects-90-rgb: 19, 19, 19;
    --circle-aspects-180-rgb: 19, 19, 19;

    /*Customizations*/

    /*houses*/
    /*--circle-houses-special-rgb-0: 0, 0, 0;*/
    /*--circle-houses-alternative-rgb-0: 239,83,80;*/

    --circle-houses-special-rgb-1: 0, 0, 0;
    --circle-houses-usual-rgb-1: 112, 112, 112;

    /*--circle-houses-usual-rgb-2: 112, 112, 112;*/

    /*aspects*/
    --circle-aspects-45-rgb-0: 0, 0, 0;
    --circle-aspects-90-rgb-0: 0, 0, 0;
    --circle-aspects-135-rgb-0: 0, 0, 0;
    --circle-aspects-150-rgb-0: 0, 0, 0;
    --circle-aspects-180-rgb-0: 0, 0, 0;

    /*zodiacs*/
    --circle-zodiacs-fire-rgb-0: 255, 138, 101;
    --circle-zodiacs-water-rgb-0: 100, 181, 246;
    --circle-zodiacs-air-rgb-0: 149, 117, 205;
    --circle-zodiacs-land-rgb-0: 174, 213, 129;

    --circle-zodiacs-fire-rgb-1: 229, 115, 115;
    --circle-zodiacs-water-rgb-1: 100, 181, 246;
    --circle-zodiacs-air-rgb-1: 226, 238, 250;
    --circle-zodiacs-land-rgb-1: 129, 199, 132;

    --circle-zodiacs-fire-rgb-2: 229, 115, 115;
    --circle-zodiacs-water-rgb-2: 100, 181, 246;
    --circle-zodiacs-air-rgb-2: 144, 164, 174;
    --circle-zodiacs-land-rgb-2: 129, 199, 132;

    --circle-zodiacs-fire-rgb-3: 239, 83, 80;
    --circle-zodiacs-water-rgb-3: 139, 195, 74;
    --circle-zodiacs-air-rgb-3: 121, 134, 203;
    --circle-zodiacs-land-rgb-3: 255, 167, 38;

    --circle-zodiacs-fire-rgb-4: 255, 167, 38;
    --circle-zodiacs-water-rgb-4: 126, 87, 194;
    --circle-zodiacs-air-rgb-4: 77, 208, 225;
    --circle-zodiacs-land-rgb-4: 141, 110, 99;

    --switcher-background: rgba(167, 167, 167, 0.2);
    --switcher-button-background: white;

    --background: #f9f9f9;
    --bg-700: #ffffff;
}
