@font-face {
    font-family: "Apercu Pro";
    src: url("src/assets/fonts/ApercuPro-Regular.woff2") format("woff2"), url("src/assets/fonts/ApercuPro-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

html {
    --disabled-layer-color: rgba(29, 29, 29, 0.8);
    --workspace-widget-background: #3b3b3b;

    overscroll-behavior-y: contain;
    height: 100%;

    --colors-blue: #4093f4;
    --colors-red: #ef5350;
    --colors-purple: #ba68c8;
    --colors-green: #73b230;
    --colors-orange: #ed682e;
    --colors-white: white;
    --colors-yellow: #f59300;
    --colors-pink: #F96AA2;
    --colors-violet: #9A69FD;

    --bg-300: rgba(34, 34, 34, 1);

    --text-alert: rgba(255, 255, 255, 0.87);

    --input-background-active: #4c4c4c;
}

body {
    margin: 0;
    width: 100%;
    height: 100%;

    background-color: var(--bg-200);
    color: var(--text-primary);
    font-size: 16px;
    font-family: "Apercu Pro";
}

* {
    touch-action: pan-x pan-y;
}

#root {
    padding: 1rem;
    line-height: 1.2em;
    height: 100%;
}

* {
    box-sizing: border-box;
}

svg {
    display: block;
}

input,
button,
select {
    outline: none;
    background: none;
    border: none;
    font-family: "Apercu Pro";
    color: var(--text-primary);
    -webkit-appearance: none;
}

input {
    font-size: 16px !important;
}

a {
    color: inherit;
    text-decoration: inherit;
}

::-webkit-calendar-picker-indicator {
    filter: invert(0.6);
    padding: 0;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
    -webkit-appearance: none;
}

@media screen and (min-aspect-ratio: 13/9) and (orientation: landscape) {
    /* #root {
        display: none;
    }

    body:before {
        content: attr(data-turn-device-msg);
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 999;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 100px;
    } */
}
