html {
    --workspace-background: #191919;

    --bg-0: #101010;
    --bg-100: #151515;
    --bg-200: #1d1d1d;
    --bg-400: #2a2a2a;
    --bg-500: #333333;
    --bg-800: #707070;
    --bg-popup: rgba(255, 255, 255, 0.1);
    --bg-popup-strong: rgb(80, 80, 80);
    --bg-popup-overlay: rgba(29, 29, 29, 0.37);
    --bg-dashboard-cell: rgba(255, 255, 255, 0.05);
    --bg-share: #151515;
    --bg-share-hover: rgba(255, 255, 255, 0.08);

    --element-neutral: rgba(255, 255, 255, 0.08);

    --input-background: rgba(0, 0, 0, 0.15);
    --input-background-hover: rgba(0, 0, 0, 0.2);
    --input-background-focus: rgba(0, 0, 0, 0.3);
    --input-background-active: #4c4c4c;

    --bg-element-neutral: rgba(255, 255, 255, 0.08);
    --bg-element-hover: rgba(255, 255, 255, 0.05);
    --bg-element-hover-hover: rgba(255, 255, 255, 0.08);

    --input-border: rgba(255, 255, 255, 0.15);
    --input-border-hover: rgba(255, 255, 255, 0.24);
    --input-border-focus: rgba(255, 255, 255, 0.34);

    --navigation-points-bg: rgba(255, 255, 255, 0.2);

    --border-lines: rgba(255, 255, 255, 0.15);

    --text-primary: rgba(255, 255, 255, 0.87);
    --text-secondary: rgba(255, 255, 255, 0.6);
    --text-third: rgba(255, 255, 255, 0.37);

    --icon-primary: rgba(255, 255, 255, 0.87);

    --color-white: #ffffff;
    --color-blue: #4093f4;
    --color-orange: rgba(245, 147, 0, 1);
    --color-red: rgba(239, 83, 80, 1);
    --color-orange: rgba(237, 104, 46, 1);
    --color-magenta: rgba(186, 104, 200, 1);
    --color-green: rgba(115, 178, 48, 1);
    --color-purple: rgba(186, 104, 200, 1);

    --accent-yellow: #f6c40f;
    --accent-pink: #ec307f;
    --accent-blue: #4093f4;

    --aspects-table-border: #4b4b4b;
    --aspects-table-border-light: #626262;
    --aspects-table-background-hover: #444444;
    --aspects-table-background-current: #5d4d4d;

    --astro-table-border: #4c4c4c;
    --astro-table-border-light: #333333;
    --astro-table-object-major: #73b330;

    --maps-tabs-bg: rgba(255, 255, 255, 0.1);
    --maps-tabs-bg-current: rgba(255, 255, 255, 0.3);

    /* MAP MAP MAP */

    --circle-internal-background: #1d1d1d;
    --circle-border: #555555;

    --circle-zodiacs-border: var(--circle-border);
    --circle-zodiacs-background: #262626;
    --circle-zodiacs-elements-common: rgba(33, 33, 33, 0.6);
    --circle-zodiacs-elements-land: #66bb6a;
    --circle-zodiacs-elements-air: #e2eef9;
    --circle-zodiacs-elements-water: #537dcf;
    --circle-zodiacs-elements-fire: #ff8a65;
    --circle-zodiacs-icons-land: var(--circle-zodiacs-elements-land);
    --circle-zodiacs-icons-air: var(--circle-zodiacs-elements-air);
    --circle-zodiacs-icons-water: var(--circle-zodiacs-elements-water);
    --circle-zodiacs-icons-fire: var(--circle-zodiacs-elements-fire);
    --circle-zodiacs-sectors-display-light: none;

    --circle-zodiacs-elements-common-rgb: 33, 33, 33, 0.6;
    --circle-zodiacs-elements-land-rgb: 102, 187, 106;
    --circle-zodiacs-elements-air-rgb: 226, 238, 249;
    --circle-zodiacs-elements-water-rgb: 83, 125, 207;
    --circle-zodiacs-elements-fire-rgb: 255, 138, 101;
    --circle-zodiacs-icons-land-rgb: var(--circle-zodiacs-elements-land-rgb);
    --circle-zodiacs-icons-air-rgb: var(--circle-zodiacs-elements-air-rgb);
    --circle-zodiacs-icons-water-rgb: var(--circle-zodiacs-elements-air-rgb);
    --circle-zodiacs-icons-fire-rgb: var(--circle-zodiacs-elements-fire-rgb);

    --circle-houses-usual: #ffffff;
    --circle-houses-special: #ef5350;
    --circle-houses-alternative: #7986cb;

    --circle-houses-usual-rgb: 255, 255, 255;
    --circle-houses-special-rgb: 239, 83, 80;
    --circle-houses-alternative-rgb: 121, 134, 203;

    --circle-aspects-0: #328bdc;
    --circle-aspects-30: rgba(255, 0, 0, 0.25);
    --circle-aspects-36: green;
    --circle-aspects-40: pink;
    --circle-aspects-45: lightgray;
    --circle-aspects-60: #ef5350;
    --circle-aspects-72: lightgreen;
    --circle-aspects-80: pink;
    --circle-aspects-90: #ffffff;
    --circle-aspects-100: pink;
    --circle-aspects-108: green;
    --circle-aspects-120: #ef5350;
    --circle-aspects-135: lightgray;
    --circle-aspects-144: green;
    --circle-aspects-150: lightgray;
    --circle-aspects-180: #ffffff;

    --circle-aspects-0-rgb: 50, 139, 220;
    --circle-aspects-30-rgb: 255, 0, 0;
    --circle-aspects-36-rgb: 0, 128, 0;
    --circle-aspects-40-rgb: 255, 192, 203;
    --circle-aspects-45-rgb: 211, 211, 211;
    --circle-aspects-60-rgb: 239, 83, 80;
    --circle-aspects-72-rgb: 144, 238, 144;
    --circle-aspects-80-rgb: 255, 192, 203;
    --circle-aspects-90-rgb: 255, 255, 255;
    --circle-aspects-100-rgb: 255, 192, 203;
    --circle-aspects-108-rgb: 0, 128, 0;
    --circle-aspects-120-rgb: 239, 83, 80;
    --circle-aspects-135-rgb: 211, 211, 211;
    --circle-aspects-144-rgb: 0, 128, 0;
    --circle-aspects-150-rgb: 211, 211, 211;
    --circle-aspects-180-rgb: 255, 255, 255;

    /*Customizations*/

    /*houses*/

    --circle-houses-usual-rgb-0: 112, 112, 112;
    --circle-houses-special-rgb-0: 239, 83, 80;
    --circle-houses-alternative-rgb-0: 100, 181, 246;

    --circle-houses-usual-rgb-1: 112, 112, 112;
    --circle-houses-special-rgb-1: 112, 112, 112;
    --circle-houses-alternative-rgb-1: 100, 181, 246;

    /*--circle-houses-usual-rgb-2: 236,236,236;*/
    /*--circle-houses-special-rgb-2: 239,83,80;*/
    /*--circle-houses-alternative-rgb-2: 100,181,246;*/

    /*aspects*/
    --circle-aspects-0-rgb-0: 64, 147, 244;
    --circle-aspects-30-rgb-0: 239, 83, 80;
    --circle-aspects-36-rgb-0: 102, 187, 106;
    --circle-aspects-40-rgb-0: 239, 83, 80;
    --circle-aspects-45-rgb-0: 255, 255, 255;
    --circle-aspects-60-rgb-0: 239, 83, 80;
    --circle-aspects-72-rgb-0: 102, 187, 106;
    --circle-aspects-80-rgb-0: 239, 83, 80;
    --circle-aspects-90-rgb-0: 255, 255, 255;
    --circle-aspects-100-rgb-0: 239, 83, 80;
    --circle-aspects-108-rgb-0: 102, 187, 106;
    --circle-aspects-120-rgb-0: 239, 83, 80;
    --circle-aspects-135-rgb-0: 255, 255, 255;
    --circle-aspects-144-rgb-0: 102, 187, 106;
    --circle-aspects-150-rgb-0: 255, 255, 255;
    --circle-aspects-180-rgb-0: 255, 255, 255;

    --circle-aspects-0-rgb-1: 64, 147, 244;
    --circle-aspects-30-rgb-1: 102, 187, 106;
    --circle-aspects-36-rgb-1: 112, 112, 112;
    --circle-aspects-40-rgb-1: 102, 187, 106;
    --circle-aspects-45-rgb-1: 239, 83, 80;
    --circle-aspects-60-rgb-1: 102, 187, 106;
    --circle-aspects-72-rgb-1: 112, 112, 112;
    --circle-aspects-80-rgb-1: 102, 187, 106;
    --circle-aspects-90-rgb-1: 239, 83, 80;
    --circle-aspects-100-rgb-1: 102, 187, 106;
    --circle-aspects-108-rgb-1: 112, 112, 112;
    --circle-aspects-120-rgb-1: 102, 187, 106;
    --circle-aspects-135-rgb-1: 239, 83, 80;
    --circle-aspects-144-rgb-1: 112, 112, 112;
    --circle-aspects-150-rgb-1: 239, 83, 80;
    --circle-aspects-180-rgb-1: 239, 83, 80;

    /*zodiacs*/
    --circle-zodiacs-fire-rgb-0: 255, 138, 101;
    --circle-zodiacs-water-rgb-0: 100, 181, 246;
    --circle-zodiacs-air-rgb-0: 149, 117, 205;
    --circle-zodiacs-land-rgb-0: 174, 213, 129;

    --circle-zodiacs-fire-rgb-1: 229, 115, 115;
    --circle-zodiacs-water-rgb-1: 100, 181, 246;
    --circle-zodiacs-air-rgb-1: 226, 238, 250;
    --circle-zodiacs-land-rgb-1: 129, 199, 132;

    --circle-zodiacs-fire-rgb-2: 229, 115, 115;
    --circle-zodiacs-water-rgb-2: 100, 181, 246;
    --circle-zodiacs-air-rgb-2: 144, 164, 174;
    --circle-zodiacs-land-rgb-2: 129, 199, 132;

    --circle-zodiacs-fire-rgb-3: 239, 83, 80;
    --circle-zodiacs-water-rgb-3: 139, 195, 74;
    --circle-zodiacs-air-rgb-3: 121, 134, 203;
    --circle-zodiacs-land-rgb-3: 255, 167, 38;

    --circle-zodiacs-fire-rgb-4: 255, 167, 38;
    --circle-zodiacs-water-rgb-4: 126, 87, 194;
    --circle-zodiacs-air-rgb-4: 77, 208, 225;
    --circle-zodiacs-land-rgb-4: 141, 110, 99;

    --switcher-background: rgba(0, 0, 0, 0.2);
    --switcher-button-background: rgba(255, 255, 255, 0.08);

    --background: #1d1d1d;
    --bg-700: #4c4c4c;
}
